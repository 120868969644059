// Import styles
import './main.scss';

// Import icons
import './icons/icons.svg';

// Import fonts used. They go through a file loader that
// put them into dist/fonts when compiled. See how this is
// handeled in line 62 in webpack.common.js
// Import fonts
import InterWoff from './fonts/Inter-Regular.woff';
import InterWoff2 from './fonts/Inter-Regular.woff2';
import InterMediumWoff from './fonts/Inter-Medium.woff';
import InterMediumWoff2 from './fonts/Inter-Medium.woff2';
import InterBoldWoff from './fonts/Inter-Bold.woff';
import InterBoldWoff2 from './fonts/Inter-Bold.woff2';

// Import dependencies
import scrollTo from 'animated-scroll-to';

// Components
import { CardsGallery } from './components/CardsGallery';
import { AjaxFilter } from './components/AjaxFilter';
import { Slider } from './components/Slider';
import { initActivityCards } from './components/ActivityCard';
import getScrollHandler from './components/ScrollHandler';

//
// Application
//
const Kongsvinger = {
  // Options / globals
  body: document.body,
  scrollHandler: null,
  ajaxFilter: null,

  init() {
    this.setupScrollTos();
    this.setupAnimations();
    this.setupIsInView();

    document.querySelector('#NavTrigger').addEventListener('click', evt => {
      document.body.classList.toggle('nav-open');
    }, false);

    // Init GardsGalleries
    [...document.querySelectorAll('.CardsGallery')].forEach(el => new CardsGallery(el));

    // Init AjaxFilter
    if(document.querySelector('.AjaxFilter')) {
      this.ajaxFilter = new AjaxFilter(document.querySelector('.AjaxFilter'));
    }

    // Init sliders
    [...document.querySelectorAll('.swiper-container')].forEach(el => new Slider(el));

    // Init ActivityCards
    this.activityCards = initActivityCards();

    // On browser resize
    // window.addEventListener('resize', () => this.onResize());
    window.addEventListener('scroll', () => this.onScroll());
  },

  onScroll(evt) {
    const currentScroll = window.pageYOffset;

    // Is at top
    if (currentScroll <= 104) {
      this.body.classList.remove('is-scrolling-down');
      this.isScrollingDown = false;
      return;
    }
    if (currentScroll <= 220) {
      this.body.classList.remove('is-scrolling-up');
      this.isScrollingUp = false;
      return;
    }

    if (currentScroll > this.lastScroll && !this.isScrollingDown) {
      this.isScrollingDown = true;
      this.body.classList.add('is-scrolling-down');
    } else if (currentScroll < this.lastScroll && this.isScrollingDown) {
      this.isScrollingDown = false;
      this.body.classList.remove('is-scrolling-down');
    }
    if (currentScroll < this.lastScroll && !this.isScrollingDown) {
      this.isScrollingUp = true;
      this.body.classList.add('is-scrolling-up');
    } else if (currentScroll > this.lastScroll && this.isScrollingDown) {
      this.isScrollingUp = false;
      this.body.classList.remove('is-scrolling-up');
    }

    this.lastScroll = currentScroll;
  },

  setupScrollTos() {
    [...document.querySelectorAll('[data-scroll-to]')].forEach((el, i) => {
      if(!el.dataset.scrollTo) {
        return;
      }

      const target = document.querySelector(el.dataset.scrollTo);

      if (!target) {
        return;
      }

      el.addEventListener('click', (evt) => {
        evt.preventDefault();

        scrollTo(target);
      });
    });
  },

  setupAnimations() {
      const handleIntersection = (entries, observer) => {
        entries.forEach(entry => {
          if(entry.isIntersecting) {
            entry.target.classList.add('is-animated');
          }
        });
      }

      let observer = new IntersectionObserver(handleIntersection, {
        // rootMargin: '0px',
        threshold: 0.5
      });

      [...document.querySelectorAll('.should-animate')].forEach(el => observer.observe(el));
    },

    setupIsInView() {
      const handleIntersection = (entries, observer) => {
        entries.forEach(entry => {
          if(entry.isIntersecting) {
            document.body.classList.add(`is-in-view-${entry.target.dataset.inView}`);
          } else {
            document.body.classList.remove(`is-in-view-${entry.target.dataset.inView}`);
          }
        });
      }

      let observer = new IntersectionObserver(handleIntersection, {
        // rootMargin: '0px',
        threshold: 0
      });

      [...document.querySelectorAll(['[data-in-view]'])].forEach(el => observer.observe(el));
    },

    initOnLoad() {
      console.log('Made with ❤️❤️❤️ by ANTI');
      console.log('Developed by Lasse Mejlvang Tvedt and Stig Melón-Bratvold 😏');
      console.log('https://anti.as');
    },

    onResize() {
      console.log('on resize');
    }
};



document.addEventListener('DOMContentLoaded', () => Kongsvinger.init());
window.addEventListener('load', () => Kongsvinger.initOnLoad());

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}


// TODO: Set up this properly
const tabs = document.querySelectorAll(".tabs-container .tabs li");
const sections = document.querySelectorAll(".tabs-container .tab-content");

tabs.forEach(tab => {
  tab.addEventListener("click", e => {
    e.preventDefault();
    removeActiveTab();
    addActiveTab(tab);
  });
})

const removeActiveTab = () => {
  tabs.forEach(tab => {
    tab.classList.remove("is-active");
  });
  sections.forEach(section => {
    section.classList.remove("is-active");
  });
}

const addActiveTab = tab => {
  tab.classList.add("is-active");
  const href = tab.querySelector("a").getAttribute("href");
  const matchingSection = document.querySelector(href);
  matchingSection.classList.add("is-active");
}
