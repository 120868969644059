import BaseComponent from '../BaseComponent';

const DRAG_EASE = 0.75;
const FRICTION = 0.89;
const DRAG_EASE_CONSTRAINS = 0.2;
const FRICTION_CONSTRAINS = 0.83;
const clamp = (num, a, b) => Math.max(Math.min(num, Math.max(a, b)), Math.min(a, b));


export class CardsGallery extends BaseComponent {
  static SUPPORTS_POINTER_EVENTS() {
    return Boolean(window.PointerEvent);
  }

  constructor(root) {
    super(...arguments);

    this.root_ = root;
    this.dragContainer_ = this.root_.children[0];
    this.items_ = [...this.dragContainer_.children];

    this.controlsContainer_ = this.root_.children[1];
    if(this.controlsContainer_) {
      this.controlsPrev_ = this.controlsContainer_.children[0];
      this.controlsNext_ = this.controlsContainer_.children[1];
    }

    // this.itemWidth = this.items_[0]?.offsetWidth || 0;

    this.hasDragged_ = false;
    this.isDragging_ = false;
    this.velocity_ = 0;
    this.RAF_ = null;

    this.offsetX = 0;
    this.prevOffsetX = this.offsetX;
    this.dragContainer_.style.transform = `translateX(${this.offsetX}px)`;
    this.root_.classList.add('is-ready');

    this.registerEventHandlers();
  }

  registerEventHandlers() {
    this.clickListener = this.handleClick.bind(this);
    this.mousedownOrTouchstartListener = this.handleMousedownOrTouchstart.bind(this);
    this.moveListener = this.handleMove.bind(this);
    this.pointerdownListener = this.handlePointerdown.bind(this);
    this.pointerupListener = this.handlePointerup.bind(this);

    this.nextClickListener = this.goForward.bind(this);
    this.prevClickListener = this.goBackward.bind(this);

    this.root_.addEventListener('click', this.clickListener);

    if (CardsGallery.SUPPORTS_POINTER_EVENTS()) {
      // If supported, use pointer events API with #setPointerCapture.
      this.root_.addEventListener('pointerdown', this.pointerdownListener);
      this.root_.addEventListener('pointerup', this.pointerupListener);
      if(this.controlsPrev_ && this.controlsNext_) {
        this.controlsPrev_.addEventListener('pointerdown', evt => evt.stopPropagation(), false);
        this.controlsNext_.addEventListener('pointerdown', evt => evt.stopPropagation(), false);
        this.controlsPrev_.addEventListener('pointerup', evt => evt.stopPropagation(), false);
        this.controlsNext_.addEventListener('pointerup', evt => evt.stopPropagation(), false);
      }
    } else {
      // Otherwise, fall back to mousedown/touchstart events.
      this.root_.addEventListener('mousedown', this.mousedownOrTouchstartListener);
      this.root_.addEventListener('touchstart', this.mousedownOrTouchstartListener);
    }

    if(this.controlsPrev_ && this.controlsNext_) {
      this.controlsPrev_.addEventListener('click', this.prevClickListener, false);
      this.controlsNext_.addEventListener('click', this.nextClickListener, false);
      this.controlsPrev_.addEventListener('touchstart', evt => evt.stopPropagation(), false);
      this.controlsNext_.addEventListener('touchstart', evt => evt.stopPropagation(), false);
      this.controlsPrev_.addEventListener('mousedown', evt => evt.stopPropagation(), false);
      this.controlsNext_.addEventListener('mousedown', evt => evt.stopPropagation(), false);
    }

  }

  goForward(evt) {
    evt.stopPropagation();
    this.calculateNextPosition(1);
  }

  goBackward(evt) {
    evt.stopPropagation();
    this.calculateNextPosition(-1);
  }

  calculateNextPosition(direction = 1) {
    if(this.isDragging_) {
      return;
    }

    const itemWidth = this.items_[0]?.offsetWidth || 0;
    const viewportWidth = this.root_.offsetWidth;
    const nPerView = Math.floor(viewportWidth / itemWidth);
    const currentPosition = Math.round(this.offsetX / itemWidth);
    const nextItem = this.items_[clamp(currentPosition + (nPerView * direction), 0, this.items_.length - 1)];
    // Next item position - padding
    const nextOffset = clamp(nextItem.offsetLeft - this.items_[0].offsetLeft, 0, this.dragContainer_.offsetWidth - this.root_.offsetWidth);
    if(nextOffset === this.offsetX) {
      return;
    }

    if(nextOffset <= 0) {
      this.root_.classList.add('is-at-start');
    } else {
      this.root_.classList.remove('is-at-start');
    }

    if(nextOffset >= this.dragContainer_.offsetWidth - this.root_.offsetWidth) {
      this.root_.classList.add('is-at-end');
    } else {
      this.root_.classList.remove('is-at-end');
    }


    this.offsetX = nextOffset;

    // Update value
    const transitionEndHandler = () => {
      this.dragContainer_.removeEventListener('transitionend', transitionEndHandler);
      this.root_.classList.remove('is-animating');
      // this.dragContainer_.style.transitionDuration = ``;
    };

    this.dragContainer_.addEventListener('transitionend', transitionEndHandler);
    this.root_.classList.add('is-animating');
    this.dragContainer_.style.transform = `translate3d(${-this.offsetX}px, 0, 0)`;
    this.prevOffsetX = this.offsetX;
  }

  handleClick(event) {
    if(this.hasDragged_) {
      event.preventDefault();
    }

    this.hasDragged_ = false;
  }

  /**
   * Handles pointer down events on the slider root element.
   */
  handleDown(event) {
    if (this.isDisabled) return;

    const clientX = event instanceof MouseEvent ? event.clientX : event.targetTouches[0].clientX;
    this.downEventClientX = clientX + this.offsetX;
    this.startClientX = clientX;
    this.clientX = clientX;
    this.isDragging_ = true;

    this.update();
  }

  /**
   * Handles pointer move events on the slider root element.
   */
  handleMove(event) {
    if (this.isDisabled) {
      return;
    }

    // Prevent scrolling
    event.preventDefault();

    this.clientX = event instanceof MouseEvent ? event.clientX : event.targetTouches[0].clientX;

    if (Math.abs(this.startClientX - this.clientX) > 7 && !this.hasDragged_) {
      this.hasDragged_ = true;
    }
  }

  /**
   * Handles pointer up events on the slider root element.
   */
  handleUp() {
    if (this.isDisabled) {
      return;
    }

    this.isDragging_ = false;

    // this.calculateNextPosition(this.velocity_ > 0 ? 1 : -1);
  }

  update() {
    cancelAnimationFrame(this.RAF_);

    const animate = () => {
      if(this.isDragging_) {
        this.offsetX -= (this.clientX - this.downEventClientX + this.offsetX) * DRAG_EASE;
        this.velocity_ = this.offsetX - this.prevOffsetX;
        this.RAF_ = requestAnimationFrame(animate);
      } else {

        this.velocity_ *= FRICTION;
        this.offsetX += this.velocity_;

        if (0 > this.offsetX) {
          this.velocity_ *= FRICTION_CONSTRAINS;
          this.offsetX -= this.offsetX * DRAG_EASE_CONSTRAINS;
        } else if (this.offsetX > this.dragContainer_.offsetWidth - this.root_.offsetWidth) {
          this.velocity_ *= FRICTION_CONSTRAINS;
          this.offsetX += (this.dragContainer_.offsetWidth - this.root_.offsetWidth - this.offsetX) * DRAG_EASE_CONSTRAINS;
        }

        if(Math.abs(this.velocity_) < 0.01) {
          this.velocity_ = 0;
        } else {
          this.RAF_ = requestAnimationFrame(animate);
        }
      }

      // Update value
      this.dragContainer_.style.transform = `translate3d(${-this.offsetX}px, 0, 0)`;
      this.prevOffsetX = this.offsetX;
    }

    this.RAF_ = requestAnimationFrame(animate);
  }

  handleMousedownOrTouchstart(event) {
    const moveEventType = event.type === 'mousedown' ? 'mousemove' : 'touchmove';
    // After a down event on the slider root, listen for move events on
    // body (so the slider value is updated for events outside of the
    // slider root).
    document.body.addEventListener(moveEventType, this.moveListener);

    const upHandler = () => {
      this.handleUp();

      // Once the drag is finished (up event on body), remove the move
      // handler.
      document.body.removeEventListener(moveEventType, this.moveListener);

      // Also stop listening for subsequent up events.
      this.root_.removeEventListener('mouseup', upHandler);
      this.root_.removeEventListener('touchend', upHandler);

      // If touch, dont bother about preventing click
      if(moveEventType === 'touchmove') {
        this.hasDragged_ = false;
      }
    };

    document.body.addEventListener('mouseup', upHandler);
    document.body.addEventListener('touchend', upHandler);

    this.handleDown(event);
  }

  handlePointerdown(event) {
    // this.root_.setPointerCapture(event.pointerId);
    this.root_.addEventListener('pointermove', this.moveListener);

    this.handleDown(event);
  }

  handlePointerup(event) {
    this.handleUp();
    this.root_.removeEventListener('pointermove', this.moveListener);

    // If touch, dont bother about preventing click
    if(event.pointerType === 'touch') {
      this.hasDragged_ = false;
    }
  }
}
