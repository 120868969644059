import Swiper from 'swiper';
import BaseComponent from '../BaseComponent';

export class Slider extends BaseComponent {
  constructor(root) {
    super(...arguments);

    this.root = root;
    this.sliderId = root.dataset.sliderId;
    console.log(this.sliderId);

    this.initSlider();
    this.initNav();
  }

  initSlider() {
    this.swiper = new Swiper(this.root, {
      slidesPerView: 1,
      spaceBetween: 16,
      grabCursor: true,

      // Breakpoints/spaces should match breakpoints and gutters set in CSS
      breakpoints: {
        // medium
        768: {
          slidesPerView: 2,
          spaceBetween: 26
        },
        // large
        1024: {
          slidesPerView: 3,
          spaceBetween: 26
        },
        // xlarge
        1440: {
          slidesPerView: 3,
          spaceBetween: 60 * 0.562548
        }
      }
    });
    this.swiper.on('slideChange', () => {
      this.onMove();
    });
  }

  initNav() {
    this.nextBtn = document.querySelector('.swiper-button-next[data-controls-slider="' + this.sliderId + '"]');
    this.prevBtn = document.querySelector('.swiper-button-prev[data-controls-slider="' + this.sliderId + '"]');
    if(this.nextBtn) {
      this.nextBtn.addEventListener('click', () => {
        this.swiper.slideNext();
      })
    }
    if(this.prevBtn) {
      this.prevBtn.addEventListener('click', () => {
        this.swiper.slidePrev();
      })
    }
  }

  onMove() {
    if(this.swiper.isBeginning) {
      this.prevBtn.style.opacity = 0.5;
      this.prevBtn.tabIndex = -1;
    } else {
      this.prevBtn.style.opacity = 1;
      this.prevBtn.tabIndex = 0;
    }
    if(this.swiper.isEnd) {
      this.nextBtn.style.opacity = 0.5;
      this.nextBtn.tabIndex = -1;
    } else {
      this.nextBtn.style.opacity = 1;
      this.nextBtn.tabIndex = 0;
    }
  }
}