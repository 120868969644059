import BaseComponent from '../BaseComponent';

export class ActivityCard extends BaseComponent {
  constructor(root) {
    super(...arguments);

    this.root = root;
    this._categoryBtn = this.root.querySelector('.Card__category__btn')

    this.initListeners();
  }

  initListeners() {
    if(this._categoryBtn) {
      // If activity card has reveal categories btn, listen for click
      const button = this._categoryBtn.firstElementChild;
      this._onCategoryClickHandler = this.onCategoryClick.bind(this);
      button.addEventListener('click', this._onCategoryClickHandler, { once: true });
    }
  }

  onCategoryClick() {
    this._categoryBtn.style.display = 'none';
    let nextSibling =  this._categoryBtn.nextElementSibling;
    while(nextSibling) {
      nextSibling.classList.add('Card__category--hidden-show');
      nextSibling = nextSibling.nextElementSibling;
    }
  }

  destroyListeners() {
    if(this._onCategoryClickHandler) {
      this._categoryBtn.removeEventListener('click', this.onCategoryClick.bind(this));
    }
  }

}

export const initActivityCards = () => {

  const activityCards = [];

  // Makes a mappings of modals with their trigger nodes
  [...document.querySelectorAll('.ActivityCard')].forEach(card => {
    activityCards.push(new ActivityCard(card));
  });

  return activityCards;
}