import axios from 'axios';
import BaseComponent from '../BaseComponent';

import { Select } from './Select';

export class AjaxFilter extends BaseComponent {
  constructor(root) {
    super(...arguments);

    this.root = root;
    this._form = this.root.querySelector('form.AjaxFilter__form');
    this._results = this.root.querySelector('.AjaxFilter__results');
    this._filteringInProgress = false;
    
    this._onChangeHandler = this.onChange.bind(this);
    this._params = null; // new URLSearchParams(window.location.search);

    this._form.addEventListener('change', this._onChangeHandler, false);
    this.initSelects();
  }

  initSelects() {
    const selects = [...this.root.querySelectorAll('.Filter__select')];
    if(selects.length > 0) {
      selects.forEach(el => new Select(el))
    }
  }

  onChange() {
    if(this._filteringInProgress) {
      return;
    }
    this.emit('filteringStarted', {});
    this._filteringInProgress = true;
    this._params = new URLSearchParams(new FormData(this._form));
    this._results.classList.remove('animate-in');
    this._results.classList.add('animate-out');

    // Allow previous results to fade out
    setTimeout(() => {
      this.search().then(results => {
        this._filteringInProgress = false;
        this._results.classList.add('animate-in');
        this._results.classList.remove('animate-out');
        if(results.data.data.length > 0) {
          const parser = new DOMParser();
          this._results.innerHTML = '<div class="grid-x grid-padding-x align-justify section-cards"></div>';
          const innerResults = this._results.querySelector('.grid-x');
          let count = 0;
          results.data.data.forEach(el => {
            count++;
            const cell = document.createElement('div');
            let cellClass = 'cell small-12 medium-6';
            if(count % 3 === 0) {
              cellClass = 'cell small-12 Card--wide';
            }
            cell.className = cellClass;
            const doc = parser.parseFromString(el.html, 'text/html');
            cell.appendChild(doc.body.firstChild);
            innerResults.appendChild(cell);
          });
          this.emit('filteringDone', results.data);
        } else {
          this._results.innerHTML = '<span></span><h2 class="mt-48">Ingen aktiviteter samsvarer med kriteriene dine</h2>';
        }
      }).catch(error => {
        console.log(error);
        this._filteringInProgress = false;
        this.emit('error', error);
      });
     }, 500);
  }

  search() {
    this.updateUrl();
    return axios.get(decodeURIComponent(`/filter.json?${this._params}`));
  }

  updateUrl() {
    const location = window.location;
    const currentPath = location.pathname.split('/page/')[0] || '/';
    window.history.replaceState({}, "", decodeURIComponent(`${currentPath}?${this._params}`));
  }
}


