import BaseComponent from '../BaseComponent';

export class Select extends BaseComponent {
  constructor(root) {
    super(...arguments);

    this.root = root;
    this.select = root.querySelector('select');
    this.label = root.querySelector('label');

    this._onChangeHandler = this.onChange.bind(this);
    this.select.addEventListener('change', this._onChangeHandler, false);
  }

  onChange() {
    // Update label with select value
    const value = this.select.options[this.select.selectedIndex].text;
    this.label.innerText = value;
  }

}


